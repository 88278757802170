.guest-pages {
    .header {
        height:50px;
    }
    .body {
        max-height: calc(100% - 100px);
    }
    .text-header {
        font-family: 'Montserrat', sans-serif;
        line-height: 1;
        font-size: 3rem;
        margin-bottom: 15px;
    }
    .text-sub-header {
        font-size: 1.333rem;
        font-weight: 500;
        opacity: 0.8;
    }
    h3 {
        font-weight: 700;
        font-size: 1.333rem;
    }
    .column {
        border-right:1px solid #ccc;
    }
    hr {
        border-top: 1px solid #ccc;
    }
    .navbutton {
        font-size: 1rem;
        text-decoration: underline;
    }
}

@media (max-width: 768px) { 
    .guest-pages {
        .body {
            height: auto !important;
        }
        .column {
            border-right:none;
        }
        .text-header {
            margin-top:15px;
        }
    }
}



