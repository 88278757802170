.dashboard {
    min-height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    .content {
        height:100%;
        overflow-y: auto;
        min-width:calc(100% - 270px);
    }
    .sidebar-wrapper {
        max-width:270px;
        padding-top:5px;
        height:100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background:#f7f7f7;
        border-right: 1px solid #ddd;
        .selected {
            background-color: #fff;
            -webkit-box-shadow: 0px 1px 3px 0 #ddd;
            box-shadow: 0px 1px 3px 0 #ddd;
        }
    }
}

@media (max-width: 768px) { 

}



