.card {
    position: relative;
    .media {
        height: 200px
    }
    box-shadow:none;
    border:1px solid #ddd;
    //-webkit-box-shadow: 0px 1px 3px 0 #ddd;
    //box-shadow: 0px 1px 3px 0 #ddd;
    .card-meta {

    }
    .header {
        position: absolute;
        width:100%;
        text-align: right;
        padding:5px 5px 5px 0;
    }
}
.empty-state {
    height:calc(100% - 100px);
}


