.wizard {
    .close-button {
        position: absolute;
        right:0px;
        top:0px;
        text-align: center;
        .label {
            color:#999;
            display:block;
            font-size: 10px;
            text-transform: uppercase;
            font-weight:600;
            margin-top:-3px;
        }
    }

    .property-widget {
        background-color: #f7f7f7;
        border:2px solid #ddd;
        border-radius: 6px;
        padding:15px;
        p {
            font-weight: 500;
        }
    }
    .saved-property-widget {
        
    }
      
}

.radio-toolbar {
    margin:7px 0 0 0;
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #f3f3f3;
        padding: 10px 20px;
        border: 2px solid #888;
        border-radius: 6px;
        margin-right:15px;
        text-align:center;
        cursor: pointer;
        p {
            text-transform: uppercase;
            font-size:0.8em;
            font-weight: 600;
            opacity: 0.8;
        }
        .wizard-icon {
            display:block;
            margin:0 auto;
        }
    }
}

.radio-toolbar label:hover {
    background-color: #dfd;
}
  
  
.radio-toolbar input[type="radio"]:checked + label {
    background-color: #bfb;
    border-color: #4c4;
}
