html, body {
	-webkit-font-smoothing: antialiased;
	height: 100%;
}

.logo-placeholder {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
	color:#000;
	font-weight: 700;
	font-size:1.111rem
}

.loading {
	background-color: #fff;
	top:0px;
	position: absolute;
}